*{
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body{
  height: 100vh;
  min-width: 280px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ff0000; 
}

::-webkit-scrollbar-thumb:hover {
  background: #900018; 
}

