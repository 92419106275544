/*
.carousel-cont{
    margin-top: 3.7em;
}
*/
.carousel-title, .highlight{
    text-align: left;
}
.highlight{
    color: #c10000;
}

.carousel-control-next, .carousel-control-prev{
    width: 5%!important;
}

.carousel-indicators{
    margin-bottom: 0!important;
}

.leave-message-btn, .franchise-btn, .register-btn{
    position: relative;
    display: block;
    font-size: 0.4rem;
    font-weight: 600;
    background: #c10000;
    padding: 0.2em;
    letter-spacing: 1px;
    border-radius: 6px;
    transition: all 0.3s ease;
    width: 300px;
}

.franchise-btn{
    right: 0;
    transform: translate(-10%, -60%);
    color: #060276!important;
    width: 80px;
}

.leave-message-btn{
    top: 0;
    transform: translate(180%, -10%);
    color: #F69BB8!important;
    width: 80px;
}

.register-btn{
    top: 0;
    transform: translate(220%, -10%);
    color: #ffffff!important;
    width: 90px;
}

.franchise-btn:hover, .leave-message-btn:hover, .register-btn:hover{
    background: transparent;
    color: #fff!important;
}

@media(min-width:420px){
    
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 0.5rem;
    }

    .franchise-btn{
        transform: translate(-10%, -80%);
        width: 80px;
    }

    .leave-message-btn{
        transform: translate(150%, -40%);
        width: 90px;
    }

    .register-btn{
        transform: translate(320%, -160%);
        width: 80px;
    }
}

@media(min-width:500px){
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 0.6rem;
    }

    .franchise-btn{
        transform: translate(-10%, -120%);
        width: 95px;
    }

    .leave-message-btn{
        transform: translate(160%, -80%);
        width: 105px;
    }

    .register-btn{
        transform: translate(345%, -230%);
        width: 90px;
    }
}

@media(min-width:600px){
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 0.8rem;
    }

    .franchise-btn{
        width: 125px;
    }

    .leave-message-btn{
        transform: translate(10%, 10%);
        width: 130px;
    }

    .register-btn{
        transform: translate(300%, -250%);
        width: 120px;
    }
}

@media(min-width:730px){
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 1rem;
    }

    .franchise-btn{
        transform: translate(-10%, -170%);
        width: 140px;
    }

    .leave-message-btn{
        transform: translate(10%, -30%);
        width: 160px;
    }

    .register-btn{
        transform: translate(320%, -280%);
        width: 140px;
    }
}

@media(min-width:900px){
    .franchise-btn{
        transform: translate(-30%, -200%);
    }

    .leave-message-btn{
        transform: translate(30%, -60%);
    }

    .register-btn{
        transform: translate(400%, -380%);
        width: 140px;
    }
}

@media(min-width:1000px){
    franchise-btn, .leave-message-btn, .register-btn{
        font-size: 1.2rem;
    }

    .franchise-btn{
        transform: translate(-40%, -210%);
        font-size: 1.2rem;
        width: 165px;
    }

    .leave-message-btn{
        width: 185px;
    }

    .register-btn{
        transform: translate(360%, -380%);
        width: 170px;
    }
}

@media(min-width:1150px){
    .carousel-title{
        transform: translate(-20%, -100%);
        font-style: normal;
        font-size: 39pt;
        width: 600px;
    }

    .register-btn{
        transform: translate(430%, -480%);
        width: 170px;
    }
}

@media(min-width:1280px){
    .carousel-title{
        transform: translate(-20%, -100%);
        font-style: normal;
        font-size: 42pt;
        width: 600px;
    }


    .register-btn{
        transform: translate(-45%, 140%);
        width: 260px;
        font-size:20pt;
    }
}

@media(min-width: 1520px){
    .carousel-title{
        transform: translate(-20%, -100%);
        font-style: normal;
        font-size: 60pt;
        width: 800px;
    }
    .register-btn{
        transform: translate(-45%, 140%);
        width: 265px;
    }
}
