.footercolor{
  color: white !important;
}

.footer {
    background-color: #c10000;
    color: #ffffff ;
    padding: 20px 0;
    text-align: center;
     justify-content: center;
  }
  
   
  .footer-line {
    border: 0;
    height: 1px;
    background: #ffffff !important;
    margin: 10px auto;
    width: 80%;
  }
  
  .footer-bottom {
    color: #ffffff;
    font-size: 14px;
  }
  
  .footer-bottom p {
    margin: 10px 0 0;
  }