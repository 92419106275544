.branchesandcustomer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.5)), url(../../img/branchescount.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    object-fit: cover;
    background-position: center;
    height:40vh;
    margin: 60px 0px;

}

.count{
    font-size: 70pt;
    color: white;
    font-weight: bold;
}

.branchesandclients{
    color: #c10000;
    font-size: 22pt;
}

.divcount{
    text-align: center;
}
@media (min-width: 992px){
    .first{
        align-items: flex-start;
    }

    .third{
        align-items: flex-end;
    }
}
