
.ltglogo{
    width: 322px;
    height: 114px;
}

.divltglogo, .divshortbackground, .betterloan, .divbetterloanpg{
    text-align: center;
    margin: 0 auto; /* Centers the div horizontally */
    display: flex;  /* Flexbox helps center the content if needed */
    justify-content: center; /* Centers content horizontally */
   
}

.shortbackground{
    margin: 30px 0px;
    font-size: 16pt;
    width: 80%;
    
}
.divbetterloanpg{
    margin-bottom: 70px;
}

.ltgred{
    color: #c10000;
}

.ltggrey{
    color: #737373
}

.betterloan{
    color: #354255;
    font-family: "Comic Neue", cursive;
    font-size: 18pt;
}

.betterloanpg {
    margin: 30px 0px;
    font-size: 14pt;
    width: 70%;
    height: 74px;
}

.learnmorebtn{
    display: block;
    text-align: center;
    width: 100px;
    text-align: center;
    margin: 0 auto 2em;
    color: #c10000!important;
    border-bottom: 2px solid transparent;
}

.learnmorebtn:hover{
    color: #900018!important;
    border-bottom: 2px solid #900018;
}



@media(min-width:992px){
    .about-p{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0 auto;
    }

    .pg{
        width: 100%;
        padding: 1em 1em 0;
        text-align: center;
    }

    .cont-img-info{
        min-height: 224px;
    }

    .cont-img-info div h3 {
        font-size: 1.5em;
    }

    .cont-img-info div p {
        font-size: 1.1em;
    }
}

@media(min-width:1200px){
    .about-cont{
        display: flex;
        padding-top: 5em;
    }

    .about-card-cont{
        padding: 0;
    }

    .pg-1{
        padding-left: 0;
        text-align: center;
    }
}


